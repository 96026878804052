<app-top-header class="top-bar-container" [bgStyle]="'solid'" [screenTitle]="order ? '#' + order?.orderNo : '' "></app-top-header>
<div class="page-content" *ngIf="!isLoading && truck">
  
  
  <div iknLoader [isActive]='isLoading || !truck' class="loader-container"></div>
  <div class="menu-block text-right" >
    <!--
            <h3 class="text-center">
                {{'Order Details'| translate}}
            </h3>
            <hr class="m-2"> -->
            <div class="d-flex justify-content-between my-2">
              <h3>{{('srv_'+order?.serviceType+'_service') | translate}}</h3>
    <h3 class=""
    [ngClass]="{
      'color-primary':order?.status === 'Accepted'
      || order?.status === 'Preparing'
      || order?.status === 'Prepared'
      || order?.status === 'PickedUp'
      || order?.status === 'Arrived',
      'text-success': order?.status === 'Completed',
      'text-danger':order?.status === 'Canceled' || order?.status === 'NotPayed',
      'text-warning': order?.status === 'PendingPayment' || order?.status === 'PendingVendorConfirm'
    }"
    >{{order?.status  | translate}}</h3>
  </div>
  <div class="d-flex justify-content-between">
    <div class="">
      <span>{{'Order Date' |translate}}:</span> {{order.submittedAt | iknDate:'df'}}
    </div>
    <div class="">
      <span>{{'Order Time' |translate}}:</span> {{order.submittedAt | iknDate: 'tf'}}
    </div>
  </div>
  <h3 class="mt-4">{{'Order Details' | translate}}</h3>
  <hr class="m-0 p-0">
    <div class="cart-item">
      <div class="d-flex flex-column" *ngFor="let item of order?.items">
        <div class="d-flex">
          <!-- <div class="mx-2" style="width:60px;height:60px;border-radius: 5px;">
            <img [src]="item?.item.mainPic" style="width:100%;height:100%;border-radius: 5px" />
          </div> -->
          <div class="flex-grow-1 d-flex flex-column  justify-content-between">
            <div class="d-flex align-items-center justify-content-between">
              <h6>{{item.qty}} X {{item.item.title | iknLocalized}}</h6>
              <h6>
                {{item.total | iknCurrency}}
                {{truck?.defaultCountry?.currencyCode | iknLocalized}}
              </h6>
            </div>


          </div>

        </div>
        <div *ngIf="item.extras && item.extras.length">
          <h6 class="py-2">{{'Extras' | translate}}</h6>
        </div>
        <div *ngFor="let extra of item.extras" class="d-flex">
          <div class="d-flex w-100">
            <div class="mx-2" style="width:45px;height:45px;border-radius: 5px">
              <img [src]="item?.item.mainPic" style="width:100%;height:100%;;border-radius: 5px" />
            </div>
            <div class="flex-grow-1 d-flex flex-column">
              <div class="d-flex align-items-center justify-content-between">
                <h6>{{extra.qty}} X {{extra.extraItem.title  | iknLocalized}}</h6>
                <h6>
                  {{extra.extraItem.price * extra.extraItem.qty | iknCurrency}}
                  {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                </h6>
              </div>
            </div>

          </div>
        </div>
        <hr class="m-0 my-2" />
      </div>
    </div>
    <div class="cart-item">
      <div class="info">
        <div class="title">
          {{'Sub Total'|translate}}
          <div class="price pull-heading-right font-bold">{{order?.subTotal | iknCurrency}}
            {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
        </div>
      </div>
      <div class="margin-top-10"></div>
      <div class="info" *ngIf="order?.collectorCharge">
        <div class="title">
          {{'Collector Fees'|translate}}
          <div class="price pull-heading-right font-bold">{{order?.collectorCharge | iknCurrency}}
            {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
        </div>
      </div>
      <div class="info">
        <div class="title">
          {{'Delivery'|translate}}
          <div class="price pull-heading-right font-bold">{{order?.deliveryCharge | iknCurrency}}
            {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
        </div>
      </div>
      <div class="info" *ngIf="order?.discount">
        <div class="title">
          {{'Discount'|translate}}
          <div class="price pull-heading-right font-bold">-{{order?.discount | iknCurrency}}
            {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
        </div>
      </div>
      <div class="info my-2">
        <div class="title">
          {{'Total'|translate}}
          <div class="price pull-heading-right font-bold">{{order?.total  | iknCurrency}}
            {{truck?.defaultCountry?.currencyCode | iknLocalized}}</div>
        </div>
      </div>
      <div class="info my-2" *ngIf="order?.promoCode">
        <div class="title">
          {{'Promo Code'|translate}}
          <div class="price pull-heading-right font-bold">{{order?.promoCode }}</div>
        </div>
      </div>
    </div>




  </div>
  <!-- <a href="https://api.whatsapp.com/send?phone=965{{truck?.supportPhone}}" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
    </a> -->
</div>


<app-footer></app-footer>
